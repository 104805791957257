import {
  GET_MODULE_ERROR,
  GET_MODULE_REQUEST,
  GET_MODULE_SUCCESS,
  GET_MODULE_VERSION_REQUEST,
  UPDATE_MODULE_REQUEST,
  UPDATE_MODULE_SUCCESS,
  LIST_LIBRARY_APPLICATIONS_ERROR,
  LIST_LIBRARY_APPLICATIONS_REQUEST,
  LIST_LIBRARY_APPLICATIONS_SUCCESS,
  LIST_LIBRARY_MODULES_ERROR,
  LIST_LIBRARY_MODULES_REQUEST,
  LIST_LIBRARY_MODULES_SUCCESS,
  GET_SSH_KEY_REQUEST,
  GET_SSH_KEY_SUCCESS,
  GET_SSH_KEY_ERROR,
  LIST_COMMITS_ERROR,
  LIST_COMMITS_REQUEST,
  LIST_COMMITS_SUCCESS,
  LIST_VIDEOS_REQUEST,
  LIST_VIDEOS_SUCCESS,
  LIST_VIDEO_REQUEST,
  LIST_VIDEO_SUCCESS,
  GET_APPLICATION_DETAIL_REQUEST,
  GET_APPLICATION_DETAIL_SUCCESS,
  GET_APPLICATION_DETAIL_ERROR,
  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_ERROR,
  CREATE_APPLICATION_ERROR,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SUCCESS,
  IMPORT_APPLICATION_ERROR,
  IMPORT_APPLICATION_REQUEST,
  IMPORT_APPLICATION_SUCCESS,
  GET_FLOW_VERSION_REQUEST,
  ADD_FLOW_DATA,
  NODE_RED_MODULES_REQUEST,
  NODE_RED_MODULES_SUCCESS,
  NODE_RED_MODULES_ERROR,
  NODE_RED_MODULE_REQUEST,
  NODE_RED_MODULE_SUCCESS,
  NODE_RED_MODULE_ERROR,
  SYNC_NODE_SUCCESS,
  SYNC_NODE_REQUEST,
  SYNC_NODE_ERROR,
  GET_DOCKER_IMAGES_REQUEST,
  GET_DOCKER_IMAGES_SUCCESS,
  GET_DOCKER_IMAGES_ERROR,
  GET_EXPERIMENTS_REQUEST,
  GET_EXPERIMENTS_SUCCESS,
  GET_EXPERIMENTS_ERROR,
  CREATE_PUBLIC_IMAGE_DOCKER_REQUEST,
  CREATE_PUBLIC_IMAGE_DOCKER_SUCCESS,
  CREATE_PUBLIC_IMAGE_DOCKER_ERROR,
  CREATE_PRIVATE_IMAGE_DOCKER_REQUEST,
  CREATE_PRIVATE_IMAGE_DOCKER_SUCCESS,
  CREATE_PRIVATE_IMAGE_DOCKER_ERROR,
  PUSH_CHANGES_REQUEST,
  PUSH_CHANGES_SUCCESS,
  PUSH_CHANGES_ERROR,
  GET_AWS_ACCESS_KEY_REQUEST,
  GET_AWS_ACCESS_KEY_SUCCESS,
  GET_AWS_ACCESS_KEY_ERROR,
  INSTALL_DEPENDENCIES_REQUEST,
  INSTALL_DEPENDENCIES_SUCCESS,
  INSTALL_DEPENDENCIES_ERROR,
  GET_MODULE_VERSION_SUCCESS,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  REMOVE_UPLOAD_MODULE_FILE,
  UPGRADE_PUBLIC_MODULE,
  CLEAR_FILE,
  LIST_TEMPLATE_REQUEST,
  LIST_TEMPLATE_SUCCESS,
  LIST_TEMPLATE_ERROR,
  ADD_APPLICATION_FROM_TEMPLATE_REQUEST,
  ADD_APPLICATION_FROM_TEMPLATE_SUCCESS,
  ADD_APPLICATION_FROM_TEMPLATE_ERROR,
  IMPORT_FILE_APPLICATION_REQUEST,
  IMPORT_FILE_APPLICATION_SUCCESS,
  IMPORT_FILE_APPLICATION_ERROR,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_FILE_SUCCESS,
  ADD_VIDEO_FILE_REQUEST,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  LIST_LIBRARY_MODELS_REQUEST,
  LIST_LIBRARY_MODELS_SUCCESS,
  LIST_LIBRARY_MODELS_ERROR,
  ADD_MODEL_FILE_REQUEST,
  LIST_MODEL_REQUEST,
  LIST_MODEL_SUCCESS,
  LIST_LIBRARY_ENVIRONMENTS_REQUEST,
  LIST_LIBRARY_ENVIRONMENTS_ERROR,
  LIST_LIBRARY_ENVIRONMENTS_SUCCESS,
  LIST_LIBRARY_IMAGE_ANNOTATION_REQUEST,
  LIST_LIBRARY_IMAGE_ANNOTATION_ERROR,
  LIST_LIBRARY_IMAGE_ANNOTATION_SUCCESS,
  LIST_LIBRARY_MODEL_TRAINING_ERROR,
  LIST_LIBRARY_MODEL_TRAINING_REQUEST,
  LIST_LIBRARY_MODEL_TRAINING_SUCCESS,
  LIST_LIBRARY_JOB_TRAINING_REQUEST,
  LIST_LIBRARY_JOB_TRAINING_SUCCESS,
  LIST_LIBRARY_JOB_TRAINING_ERROR,
  LIST_ENVIRONMENT_REQUEST,
  LIST_ENVIRONMENT_SUCCESS,
  LIST_DATASET_REQUEST,
  LIST_DATASET_SUCCESS,
  LIST_DATASET_ERROR,
  DETAIL_DATASET_REQUEST,
  DETAIL_DATASET_SUCCESS,
  DETAIL_DATASET_ERROR,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_ERROR,
  LOADING_PARAMS,
  DETAILS_LIBRARY_JOB_TRAINING_REQUEST,
  DETAILS_LIBRARY_JOB_TRAINING_SUCCESS,
  DETAILS_LIBRARY_JOB_TRAINING_ERROR,
  LIST_LIBRARY_RELATED_MODELS_REQUEST,
  LIST_LIBRARY_RELATED_MODELS_SUCCESS,
  LIST_LIBRARY_ANNOTATION_TASK_REQUEST,
  LIST_LIBRARY_ANNOTATION_TASK_SUCCESS,
  LIST_LIBRARY_ANNOTATION_TASK_ERROR,
  LIST_LIBRARY_ANNOTATION_JOB_REQUEST,
  LIST_LIBRARY_ANNOTATION_JOB_SUCCESS,
  LIST_LIBRARY_ANNOTATION_JOB_ERROR,
} from './constants';

export function getSSHKeyRequest(payload) {
  return {
    type: GET_SSH_KEY_REQUEST,
    payload,
  };
}

export function getSSHKeySuccess(payload) {
  return {
    type: GET_SSH_KEY_SUCCESS,
    payload,
  };
}

export function getSSHKeyError(payload) {
  return {
    type: GET_SSH_KEY_ERROR,
    payload,
  };
}


export function getExperimentsRequest(payload) {
  return {
    type: GET_EXPERIMENTS_REQUEST,
    payload,
  };
}

export function getExperimentsSuccess(payload) {
  return {
    type: GET_EXPERIMENTS_SUCCESS,
    payload,
  };
}

export function getExperimentsError(payload) {
  return {
    type: GET_EXPERIMENTS_ERROR,
    payload,
  };
}

export function getModuleRequest(payload, meta) {
  return {
    type: GET_MODULE_REQUEST,
    payload,
    meta,
  };
}

export function getModuleVersionRequest(payload) {
  return {
    type: GET_MODULE_VERSION_REQUEST,
    payload,
  };
}

export function getModuleVersionSuccess(payload) {
  return {
    type: GET_MODULE_VERSION_SUCCESS,
    payload,
  };
}

export function getModuleSuccess(payload) {
  return {
    type: GET_MODULE_SUCCESS,
    payload,
  };
}

export function getModuleError(errors) {
  return {
    type: GET_MODULE_ERROR,
    errors,
  };
}

export function updateModuleRequest(payload, onCallback) {
  return {
    type: UPDATE_MODULE_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function updateModuleSuccess(payload) {
  return {
    type: UPDATE_MODULE_SUCCESS,
    payload,
  };
}


export function libraryFlowsListRequest(payload) {
  return {
    type: LIST_LIBRARY_APPLICATIONS_REQUEST,
    payload,
  };
}

export function libraryFlowsListSuccess(payload) {
  return {
    type: LIST_LIBRARY_APPLICATIONS_SUCCESS,
    payload,
  };
}

export function libraryFlowsListError(errors) {
  return {
    type: LIST_LIBRARY_APPLICATIONS_ERROR,
    errors,
  };
}

export function libraryModulesListRequest(payload) {
  return {
    type: LIST_LIBRARY_MODULES_REQUEST,
    payload,
  };
}

export function libraryModulesListSuccess(payload) {
  return {
    type: LIST_LIBRARY_MODULES_SUCCESS,
    payload,
  };
}

export function libraryModulesListError(errors) {
  return {
    type: LIST_LIBRARY_MODULES_ERROR,
    errors,
  };
}

export function getCommitsRequest(payload) {
  return {
    type: LIST_COMMITS_REQUEST,
    payload,
  };
}

export function getCommitsSuccess(payload) {
  return {
    type: LIST_COMMITS_SUCCESS,
    payload,
  };
}

export function getCommitsError(errors) {
  return {
    type: LIST_COMMITS_ERROR,
    errors,
  };
}

export function getApplicationDetailRequest(payload) {
  return {
    type: GET_APPLICATION_DETAIL_REQUEST,
    payload,
  };
}

export function getFlowVersionRequest(payload) {
  return {
    type: GET_FLOW_VERSION_REQUEST,
    payload,
  };
}

export function addInformationData(payload) {
  return {
    type: ADD_FLOW_DATA,
    payload,
  };
}

export function getFlowDetailSuccess(payload) {
  return {
    type: GET_APPLICATION_DETAIL_SUCCESS,
    payload,
  };
}

export function getFlowDetailError(errors) {
  return {
    type: GET_APPLICATION_DETAIL_ERROR,
    errors,
  };
}

export function updateApplicationRequest(payload, meta) {
  return {
    type: UPDATE_APPLICATION_REQUEST,
    payload,
    meta,
  };
}

export function updateFlowSuccess(payload) {
  return {
    type: UPDATE_APPLICATION_SUCCESS,
    payload,
  };
}

export function updateFlowError(errors) {
  return {
    type: UPDATE_APPLICATION_ERROR,
    errors,
  };
}

export function createFlowRequest(payload, onCallback) {
  return {
    type: CREATE_APPLICATION_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function createFlowSuccess(payload) {
  return {
    type: CREATE_APPLICATION_SUCCESS,
    payload,
  };
}

export function createFlowError(errors) {
  return {
    type: CREATE_APPLICATION_ERROR,
    errors,
  };
}

export function cloneApplicationRequest(payload, onCallback) {
  return {
    type: IMPORT_APPLICATION_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function cloneApplicationSuccess(payload) {
  return {
    type: IMPORT_APPLICATION_SUCCESS,
    payload,
  };
}

export function cloneApplicationError(errors) {
  return {
    type: IMPORT_APPLICATION_ERROR,
    errors,
  };
}

export function getNodeRedModulesRequest(payload) {
  return {
    type: NODE_RED_MODULES_REQUEST,
    payload,
  };
}

export function getNodeRedModulesSuccess(payload) {
  return {
    type: NODE_RED_MODULES_SUCCESS,
    payload,
  };
}

export function getNodeRedModulesError(errors) {
  return {
    type: NODE_RED_MODULES_ERROR,
    errors,
  };
}

export function getNodeRedModuleRequest(payload) {
  return {
    type: NODE_RED_MODULE_REQUEST,
    payload,
  };
}

export function getNodeRedModuleSuccess(payload) {
  return {
    type: NODE_RED_MODULE_SUCCESS,
    payload,
  };
}

export function getNodeRedModuleError(errors) {
  return {
    type: NODE_RED_MODULE_ERROR,
    errors,
  };
}

export function syncNodeRequest(payload) {
  return {
    type: SYNC_NODE_REQUEST,
    payload,
  };
}

export function syncNodeSuccess(payload) {
  return {
    type: SYNC_NODE_SUCCESS,
    payload,
  };
}

export function syncNodeError(errors) {
  return {
    type: SYNC_NODE_ERROR,
    errors,
  };
}

export function getDockerImagesRequest(payload) {
  return {
    type: GET_DOCKER_IMAGES_REQUEST,
    payload,
  };
}

export function getDockerImagesSuccess(payload) {
  return {
    type: GET_DOCKER_IMAGES_SUCCESS,
    payload,
  };
}

export function getDockerImagesError(errors) {
  return {
    type: GET_DOCKER_IMAGES_ERROR,
    errors,
  };
}

export function createPublicDockerImageRequest(payload) {
  return {
    type: CREATE_PUBLIC_IMAGE_DOCKER_REQUEST,
    payload,
  };
}

export function createPublicDockerImageSuccess(payload) {
  return {
    type: CREATE_PUBLIC_IMAGE_DOCKER_SUCCESS,
    payload,
  };
}

export function createPublicDockerImageError(errors) {
  return {
    type: CREATE_PUBLIC_IMAGE_DOCKER_ERROR,
    errors,
  };
}

export function createPrivateDockerImageRequest(payload, onCallback) {
  return {
    type: CREATE_PRIVATE_IMAGE_DOCKER_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function createPrivateDockerImageSuccess(payload) {
  return {
    type: CREATE_PRIVATE_IMAGE_DOCKER_SUCCESS,
    payload,
  };
}

export function createPrivateDockerImageError(errors) {
  return {
    type: CREATE_PRIVATE_IMAGE_DOCKER_ERROR,
    errors,
  };
}

export function getAwsAccessKeyRequest(onCallback) {
  return {
    type: GET_AWS_ACCESS_KEY_REQUEST,
    payload: { onCallback },
  };
}

export function getAwsAccessKeySuccess(payload) {
  return {
    type: GET_AWS_ACCESS_KEY_SUCCESS,
    payload,
  };
}

export function getAwsAccessKeyError(errors) {
  return {
    type: GET_AWS_ACCESS_KEY_ERROR,
    errors,
  };
}

export function pushChangesRequest(payload, meta) {
  return {
    type: PUSH_CHANGES_REQUEST,
    payload,
    meta,
  };
}

export function pushChangesSuccess(payload) {
  return {
    type: PUSH_CHANGES_SUCCESS,
    payload,
  };
}

export function pushChangesError(errors) {
  return {
    type: PUSH_CHANGES_ERROR,
    errors,
  };
}

export function installDependenciesRequest(payload, meta) {
  return {
    type: INSTALL_DEPENDENCIES_REQUEST,
    payload,
    meta,
  };
}

export function installDependenciesSuccess(payload) {
  return {
    type: INSTALL_DEPENDENCIES_SUCCESS,
    payload,
  };
}

export function installDependenciesError(errors) {
  return {
    type: INSTALL_DEPENDENCIES_ERROR,
    errors,
  };
}

export function uploadModuleFileRequest(payload, meta, onCallback) {
  return {
    type: UPLOAD_FILE_REQUEST,
    payload,
    meta,
    onCallback
  };
}

export function uploadApplicationFileRequest(payload, meta) {
  return {
    type: IMPORT_FILE_APPLICATION_REQUEST,
    payload,
    meta,
  };
}

export function uploadModelRequest(payload, config) {
  return {
    type: ADD_MODEL_FILE_REQUEST,
    payload,
    meta: config,
  };
}


export function removeModuleFile(payload) {
  return {
    type: REMOVE_UPLOAD_MODULE_FILE,
    payload,
  };
}

export function uploadModuleFileSuccess(payload) {
  return {
    type: IMPORT_FILE_APPLICATION_SUCCESS,
    payload,
  };
}


export function uploadApplicationFileSuccess(payload) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload,
  };
}

export function uploadModuleFileError(errors) {
  return {
    type: IMPORT_FILE_APPLICATION_ERROR,
    errors,
  };
}

export function uploadApplicationFileError(errors) {
  return {
    type: IMPORT_FILE_APPLICATION_ERROR,
    errors,
  };
}

export function upgradePublicModule(payload, onCallback) {
  return {
    type: UPGRADE_PUBLIC_MODULE,
    payload,
    meta: { onCallback },
  };
}

export function onClearFile() {
  return {
    type: CLEAR_FILE,
  };
}

export function getTemplateListRequest(payload, meta) {
  return {
    type: LIST_TEMPLATE_REQUEST,
    payload,
    meta,
  };
}

export function getTemplateListSuccess(payload) {
  return {
    type: LIST_TEMPLATE_SUCCESS,
    payload,
  };
}

export function getTemplateListError(errors) {
  return {
    type: LIST_TEMPLATE_ERROR,
    errors,
  };
}

export function addApplicationFromTemplateRequest(payload, meta) {
  return {
    type: ADD_APPLICATION_FROM_TEMPLATE_REQUEST,
    payload,
    meta,
  };
}

export function addApplicationFromTemplateSuccess(payload) {
  return {
    type: ADD_APPLICATION_FROM_TEMPLATE_SUCCESS,
    payload,
  };
}

export function addApplicationFromTemplateError(errors) {
  return {
    type: ADD_APPLICATION_FROM_TEMPLATE_ERROR,
    errors,
  };
}

export function addVideoFileSuccess(payload) {
  return {
    type: ADD_VIDEO_FILE_SUCCESS,
    payload,
  };
}

export function addVideoFileRequest(payload, meta) {
  return {
    type: ADD_VIDEO_FILE_REQUEST,
    payload,
    meta
  };
}

export function addVideoRequest(payload) {
  return {
    type: ADD_VIDEO_SUCCESS,
    payload,
  };
}

export function addVideoSuccess(payload) {
  return {
    type: ADD_VIDEO_FILE_REQUEST,
    payload,
  };
}

export function listVideosRequest(payload) {
  return {
    type: LIST_VIDEOS_REQUEST,
    payload,
  };
}

export function listVideosSuccess(payload) {
  return {
    type: LIST_VIDEOS_SUCCESS,
    payload,
  };
}

export function listVideoRequest(payload) {
  return {
    type: LIST_VIDEO_REQUEST,
    payload,
  };
}

export function listVideoSuccess(payload) {
  return {
    type: LIST_VIDEO_SUCCESS,
    payload,
  };
}


export function updateVideoRequest(payload, onCallback) {
  return {
    type: UPDATE_VIDEO_REQUEST,
    payload,
    meta: onCallback,
  };
}

export function updateVideoSuccess(payload) {
  return {
    type: UPDATE_VIDEO_SUCCESS,
    payload,
  };
}

export function libraryModelListRequest(payload) {
  return {
    type: LIST_LIBRARY_MODELS_REQUEST,
    payload,
  };
}

export function libraryModelListSuccess(payload) {
  return {
    type: LIST_LIBRARY_MODELS_SUCCESS,
    payload,
  };
}

export function libraryModelListError(errors) {
  return {
    type: LIST_LIBRARY_MODELS_ERROR,
    errors,
  };
}

export function libraryRelatedModelListRequest(payload) {
  return {
    type: LIST_LIBRARY_RELATED_MODELS_REQUEST,
    payload,
  };
}

export function libraryRelatedModelListSuccess(payload) {
  return {
    type: LIST_LIBRARY_RELATED_MODELS_SUCCESS,
    payload,
  };
}

export function libraryRelatedModelListError(errors) {
  return {
    type: LIST_LIBRARY_RELATED_MODELS_ERROR,
    errors,
  };
}

export function libraryModelRequest(payload) {
  return {
    type: LIST_MODEL_REQUEST,
    payload,
  };
}

export function libraryModelSuccess(payload) {
  return {
    type: LIST_MODEL_SUCCESS,
    payload,
  };
}

export function libraryEnvironmentListRequest(payload, meta) {
  return {
    type: LIST_LIBRARY_ENVIRONMENTS_REQUEST,
    payload,
    meta,

  };
}

export function libraryEnvironmentListSuccess(payload) {
  return {
    type: LIST_LIBRARY_ENVIRONMENTS_SUCCESS,
    payload,
  };
}

export function libraryEnvironmentListError(errors) {
  return {
    type: LIST_LIBRARY_ENVIRONMENTS_ERROR,
    errors,
  };
}

export function libraryEnvironmentRequest(payload) {
  return {
    type: LIST_ENVIRONMENT_REQUEST,
    payload,
  };
}

export function libraryEnvironmentSuccess(payload) {
  return {
    type: LIST_ENVIRONMENT_SUCCESS,
    payload,
  };
}

export function libraryImageAnnotationListRequest(payload, meta) {
  return {
    type: LIST_LIBRARY_IMAGE_ANNOTATION_REQUEST,
    payload,
    meta,

  };
}

export function libraryImageAnnotationListSuccess(payload) {
  return {
    type: LIST_LIBRARY_IMAGE_ANNOTATION_SUCCESS,
    payload,
  };
}

export function libraryImageAnnotationError(errors) {
  return {
    type: LIST_LIBRARY_MODEL_TRAINING_ERROR,
    errors,
  };
}

export function libraryModelTrainingListRequest(payload, meta) {
  return {
    type: LIST_LIBRARY_MODEL_TRAINING_REQUEST,
    payload,
    meta,

  };
}

export function libraryModelTrainingListSuccess(payload) {
  return {
    type: LIST_LIBRARY_MODEL_TRAINING_SUCCESS,
    payload,
  };
}

export function libraryModelTrainingListError(errors) {
  return {
    type: LIST_LIBRARY_MODEL_TRAINING_ERROR,
    errors,
  };
}

export function getDatasetListRequest(payload, meta) {
  return {
    type: LIST_DATASET_REQUEST,
    payload,
    meta,

  };
}

export function getDatasetListSuccess(payload) {
  return {
    type: LIST_DATASET_SUCCESS,
    payload,
  };
}

export function getDatasetListError(errors) {
  return {
    type: LIST_DATASET_ERROR,
    errors,
  };
}

export function getDatasetDetailRequest(payload, meta) {
  return {
    type: DETAIL_DATASET_REQUEST,
    payload,
    meta,

  };
}

export function getDatasetDetailSuccess(payload) {
  return {
    type: DETAIL_DATASET_SUCCESS,
    payload,
  };
}

export function getDatasetDetailError(errors) {
  return {
    type: DETAIL_DATASET_ERROR,
    errors,
  };
}

export function addFiles(payload, meta, update) {
  return {
    type: UPLOAD_FILES_REQUEST,
    payload,
    meta,
    update
  };
}


export function addFilesSuccess(payload) {
  return {
    type: UPLOAD_FILES_SUCCESS,
    payload,
  };
}


export function addFilesError(payload) {
  return {
    type: UPLOAD_FILES_ERROR,
    payload,
  };
}

export function onLoadingParams(payload) {
  return {
    type: LOADING_PARAMS,
    payload,
  };
}

export function libraryJobTrainingListRequest(payload, meta) {
  return {
    type: LIST_LIBRARY_JOB_TRAINING_REQUEST,
    payload,
    meta,

  };
}

export function libraryJobTrainingListSuccess(payload) {
  return {
    type: LIST_LIBRARY_JOB_TRAINING_SUCCESS,
    payload,
  };
}

export function libraryJobTrainingListError(errors) {
  return {
    type: LIST_LIBRARY_JOB_TRAINING_ERROR,
    errors,
  };
}


export function libraryJobTrainingDetailsRequest(payload, meta) {
  return {
    type: DETAILS_LIBRARY_JOB_TRAINING_REQUEST,
    payload,
    meta,

  };
}

export function libraryJobTrainingDetailsSuccess(payload) {
  return {
    type: DETAILS_LIBRARY_JOB_TRAINING_SUCCESS,
    payload,
  };
}

export function libraryJobTrainingDetailsError(errors) {
  return {
    type: DETAILS_LIBRARY_JOB_TRAINING_ERROR,
    errors,
  };
}

export function libraryAnnotationTaskRequest(payload, meta) {
  return {
    type: LIST_LIBRARY_ANNOTATION_TASK_REQUEST,
    payload,
    meta,
  };
}

export function libraryAnnotationTaskSuccess(payload) {
  return {
    type: LIST_LIBRARY_ANNOTATION_TASK_SUCCESS,
    payload,
  };
}

export function libraryAnnotationTaskError(errors) {
  return {
    type: LIST_LIBRARY_ANNOTATION_TASK_ERROR,
    errors,
  };
}


export function libraryAnnotationJobRequest(payload, meta) {
  return {
    type: LIST_LIBRARY_ANNOTATION_JOB_REQUEST,
    payload,
    meta,
  };
}

export function libraryAnnotationJobSuccess(payload) {
  return {
    type: LIST_LIBRARY_ANNOTATION_JOB_SUCCESS,
    payload,
  };
}

export function libraryAnnotationJobError(errors) {
  return {
    type: LIST_LIBRARY_ANNOTATION_JOB_ERROR,
    errors,
  };
}
