import React from 'react';

export default () => (
  <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.3074 14.9287C21.0052 14.9301 20.7071 14.9996 20.4355 15.1322L17.5176 12.2144H12.0645L11.4893 13.5715H16.955L19.476 16.0924C19.3432 16.3641 19.2734 16.6621 19.2717 16.9644C19.2717 17.367 19.3911 17.7606 19.6148 18.0954C19.8385 18.4302 20.1564 18.6911 20.5284 18.8452C20.9004 18.9993 21.3097 19.0396 21.7046 18.961C22.0995 18.8825 22.4622 18.6886 22.7469 18.4039C23.0316 18.1192 23.2255 17.7565 23.3041 17.3616C23.3826 16.9667 23.3423 16.5573 23.1882 16.1854C23.0341 15.8134 22.7732 15.4954 22.4384 15.2718C22.1037 15.0481 21.7101 14.9287 21.3074 14.9287ZM21.3074 17.643C21.1732 17.643 21.042 17.6032 20.9304 17.5286C20.8188 17.4541 20.7319 17.3481 20.6805 17.2241C20.6292 17.1001 20.6157 16.9637 20.6419 16.832C20.6681 16.7004 20.7327 16.5795 20.8276 16.4846C20.9225 16.3897 21.0434 16.325 21.1751 16.2989C21.3067 16.2727 21.4431 16.2861 21.5671 16.3375C21.6911 16.3888 21.7971 16.4758 21.8717 16.5874C21.9462 16.699 21.986 16.8302 21.986 16.9644C21.986 17.1444 21.9145 17.317 21.7873 17.4442C21.66 17.5715 21.4874 17.643 21.3074 17.643Z" fill="#FF6F91" />
    <path d="M21.3077 7.46436C20.8881 7.4656 20.4791 7.59673 20.1369 7.83973C19.7948 8.08273 19.5363 8.42569 19.3969 8.82152H13.5231L12.9404 10.1787H19.3969C19.5219 10.5296 19.7409 10.8394 20.0301 11.0743C20.3193 11.3092 20.6675 11.46 21.0366 11.5104C21.4057 11.5607 21.7816 11.5087 22.1231 11.3599C22.4647 11.2111 22.7587 10.9713 22.9732 10.6666C23.1876 10.362 23.3142 10.0043 23.3391 9.63258C23.3641 9.26087 23.2863 8.88946 23.1144 8.55895C22.9425 8.22843 22.6831 7.95152 22.3645 7.75848C22.0458 7.56544 21.6803 7.46369 21.3077 7.46436ZM21.3077 10.1787C21.1735 10.1787 21.0423 10.1389 20.9307 10.0643C20.8191 9.98975 20.7322 9.88377 20.6808 9.75977C20.6294 9.63578 20.616 9.49934 20.6422 9.36771C20.6684 9.23608 20.733 9.11517 20.8279 9.02027C20.9228 8.92537 21.0437 8.86074 21.1753 8.83455C21.307 8.80837 21.4434 8.82181 21.5674 8.87317C21.6914 8.92453 21.7974 9.0115 21.8719 9.1231C21.9465 9.23469 21.9863 9.36588 21.9863 9.50009C21.9863 9.68006 21.9148 9.85266 21.7876 9.97992C21.6603 10.1072 21.4877 10.1787 21.3077 10.1787Z" fill="#FF6F91" />
    <path d="M21.3073 8.53506e-07C20.7676 0.000539791 20.2501 0.215192 19.8685 0.596849C19.4868 0.978507 19.2721 1.49599 19.2716 2.03574C19.2739 2.36081 19.3549 2.68049 19.5077 2.96742L16.9617 5.42863H14.9784L14.4136 6.78579H17.51L20.4958 3.90047C20.7731 4.02142 21.0739 4.07899 21.3764 4.06901C21.6788 4.05902 21.9752 3.98172 22.2439 3.84275C22.5127 3.70377 22.7471 3.5066 22.9301 3.26561C23.113 3.02461 23.24 2.74585 23.3016 2.44962C23.3632 2.15338 23.358 1.84713 23.2864 1.55316C23.2147 1.25919 23.0784 0.984889 22.8874 0.750238C22.6964 0.515587 22.4554 0.326487 22.1821 0.196705C21.9087 0.0669224 21.6099 -0.000276246 21.3073 8.53506e-07ZM21.3073 2.71431C21.1731 2.71431 21.0419 2.67452 20.9303 2.59995C20.8187 2.52539 20.7318 2.41941 20.6804 2.29542C20.6291 2.17142 20.6156 2.03498 20.6418 1.90335C20.668 1.77172 20.7326 1.65081 20.8275 1.55591C20.9224 1.46101 21.0433 1.39638 21.175 1.3702C21.3066 1.34401 21.443 1.35745 21.567 1.40881C21.691 1.46017 21.797 1.54715 21.8716 1.65874C21.9461 1.77033 21.9859 1.90153 21.9859 2.03574C21.9859 2.21571 21.9144 2.38831 21.7872 2.51556C21.6599 2.64282 21.4873 2.71431 21.3073 2.71431Z" fill="#FF6F91" />
    <path d="M13.0587 0.950195C13.3858 0.950195 13.6661 1.05756 13.8997 1.27228C14.1333 1.487 14.2501 1.74773 14.2501 2.05448C14.2501 2.22319 14.2112 2.39957 14.1333 2.58362L8.22304 16.3412C8.09844 16.6019 7.92713 16.7936 7.7091 16.9163C7.50664 17.039 7.29639 17.1004 7.07835 17.1004C6.86032 17.085 6.65007 17.016 6.44761 16.8933C6.26072 16.7706 6.11277 16.5942 6.00375 16.3642L0.0934436 2.58362C0.0622957 2.50693 0.0389348 2.43024 0.0233609 2.35356C0.00778695 2.26153 0 2.16951 0 2.07749C0 1.72473 0.132378 1.44866 0.397135 1.24927C0.661892 1.04989 0.918862 0.950195 1.16804 0.950195C1.66641 0.950195 2.0324 1.20326 2.26601 1.70939L7.61565 14.2016H6.93819L11.9841 1.68639C12.2022 1.19559 12.5604 0.950195 13.0587 0.950195Z" fill="#FF6F91" />
  </svg>
);
