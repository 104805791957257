import { fromJS, Map } from 'immutable';

import { SELECT_WORKSPACE_SUCCESS } from 'containers/Login/constants';
import {
  LOGOUT,
  LOADING,
  DISPLAY_FULL_SIDEBAR,
  DISPLAY_NOTIFICATION_BAR,
  LOGGED_IN_USER_DATA_SUCCESS,
  UPDATE_DEVICE_TYPE,
  FIRST_TIME_LOGGED_IN,
  DISPLAY_INFRA_BANNER,
  UPLOAD_TRACKER,
  DISPLAY_FULL_DATASET_SIDEBAR,
  UPDATE_SOCKET_ID,
  UPDATE_MODEL_URL
} from './constants';

const initialState = new Map({
  loading: false,
  error: false,
  user: null,
  device: 'desktop',
  hideFullSidebar: false,
  hideFullDatasetSidebar: true,
  showPendingInfraBanner: false,
  isLoggedIn: false,
  showNotification: false,
  userData: fromJS({}),
  workspaces: [],
  uploadTracker: [],
  socketId: '',
  modelUrl: {}
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
    case SELECT_WORKSPACE_SUCCESS:
      return state.merge({
        loading: false,
        error: false,
        user: null,
        device: state.toJS() && state.toJS().device,
        hideFullSidebar: state.toJS() && state.toJS().device === 'mobile',
        showPendingInfraBanner: false,
        isLoggedIn: false,
        showNotification: false,
        userData: fromJS({}),
        workspaces: [],
      });
    case FIRST_TIME_LOGGED_IN:
      return state.update('isLoggedIn', () => action.payload);
    case UPDATE_DEVICE_TYPE:
      return state.update('device', () => action.payload);
    case LOADING:
      return state.update('loading', () => action.loading);
    case DISPLAY_INFRA_BANNER:
      return state.update('showPendingInfraBanner', () => action.payload);
    case LOGGED_IN_USER_DATA_SUCCESS:
      return state.update('userData', () => action.payload);
    case DISPLAY_NOTIFICATION_BAR:
      return state.update('showNotification', () => action.payload);
    case UPDATE_SOCKET_ID:
      return state.update('socketId', () => action.payload);
    case DISPLAY_FULL_SIDEBAR:
      return state.update('hideFullSidebar', () => action.payload);
    case DISPLAY_FULL_DATASET_SIDEBAR:
      return state.update('hideFullDatasetSidebar', () => !action.payload);
    case UPLOAD_TRACKER:
      return state.update('uploadTracker', () => action.payload)
    case UPDATE_MODEL_URL:
      return state.update('modelUrl', () => action.payload)
    default:
      return state;
  }
}

export default appReducer;
