import React from 'react';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';

import { SearchIcon, CloseIcon } from 'images/common';
import messages from 'utils/messages';

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  state = {
    filterValue: [],
    filterId: '',
    searchValue: '',
    columns: [],
  }

  componentDidMount() {
    const { columns } = this.props;

    this.setState({ columns });
    document.addEventListener('keydown', this.onEnter, false);
  }

  onEnter = (event) => {
    if (event.key === 'Enter' && !!this.state.filterValue.length) {
      // on Esc button click it should close popup
      this.onApply();
    }
  }

  onClear = () => {
    this.textInput.current.value = '';

    this.setState({ searchValue: '' });
    const { columns } = this.props;

    this.setState({ columns });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }

  onApply = () => {
    const { filterValue, filterId } = this.state;
    const { name, onFilterApply } = this.props;

    onFilterApply(name, filterValue, 'checkbox', filterId);
  }

  onChange = (value) => {
    const selectedValue = this.state.filterValue;
    const selectedId = this.state.filterId;
    const indexValue = this.state.filterValue.indexOf(value.label);

    if (indexValue > -1) {
      selectedValue.splice(indexValue, 1);
      selectedId.splice(indexValue, 1);
      return this.setState({
        filterValue: selectedValue,
        filterId: selectedId,
      });
    }

    return this.setState({
      filterValue: [...this.state.filterValue, value.label],
      filterId: [...this.state.filterId, value.name],
    });
  }

  onSearch = (event) => {
    const { columns } = this.props;
    const searchValue = event.target.value;

    const filteredColumns = columns
      .filter(column => column
        .label.toLowerCase().includes(searchValue.toLowerCase()));

    this.setState({ columns: filteredColumns, searchValue });
  }

  render() {
    const { title, customClass, columns } = this.props;
    const { filterValue } = this.state;

    const customInputClass = `text-field text-field_nolabel text-field_${customClass}`;

    const checkBoxInputClass = `checkbox checkbox_blocked checkbox_${customClass} hideOverflow`;

    const iconClass = classNames('cursor', {
      'text-field__closeIcon': this.state.searchValue,
    });

    console.log('testse', columns);

    return (
      <>
        <div className="filters-bar__heading">{title}</div>

        <div className={customInputClass}>
          <input
            type="text"
            placeholder="Search"
            className="text-field__input"
            onChange={this.onSearch}
            ref={this.textInput}
          />
          <div className="text-field__underscore" />
          <div className="text-field__icon">{!this.state.searchValue && <SearchIcon />}</div>
        </div>
        {this.state.searchValue && <span className={iconClass} onClick={() => this.onClear()}><CloseIcon /></span>}
        <Scrollbars
          autoHeight
          autoHide
          autoHideDuration={1}
          autoHeightMax={columns.length > 0 ? 250 : 0}
          autoHeightMin={100}
          renderView={props => <div {...props} className="view" />}
        >
          {uniqBy(columns, 'label')
            .filter(column => column.label)
            .map(column => (
              <div className={checkBoxInputClass} key={column.name}>
                <input type="checkbox" className="checkbox__input" onClick={() => this.onChange(column)} checked={filterValue.includes(column.label)} readOnly />
                <div className="checkbox__check" />
                <label className="checkbox__label">{column.label}</label>
              </div>
            ))}
        </Scrollbars>
        <hr className="mt_100" />
        <button type="button" className={`filters-bar__apply ${customClass}`} onClick={this.onApply}>
          <FormattedMessage {...messages.apply} />
        </button>
      </>
    );
  }
}

MultiSelect.propTypes = {
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  columns: PropTypes.array,
  customClass: PropTypes.string,
  onFilterApply: PropTypes.func,
};

export default MultiSelect;
